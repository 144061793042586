
import { getOrCreateDataObject } from "o365.vue.ts";

class OperationContext {
    constructor() {
        this.dataObject = getOrCreateDataObject({
            id: "mobile.controls.OperationContext.js:dataObject",
            viewName: "sviw_System_MyOperationContext",
            fields: [
                { name: "ID"         , type: "number" },
                { name: "IdPath"     , type: "string" },
                { name: "Domain_ID"  , type: "string" },
                { name: "Person_ID"  , type: "number" },
                { name: "OrgUnit"    , type: "string" },
                { name: "OrgUnitName", type: "string" }
            ],
        });
        this.dataObject.load();

        this.lookup = getOrCreateDataObject({
            id: "mobile.controls.OperationContext.js:lookup",
            viewName: "sviw_System_MyOrgUnitsRoles",
            whereClause: "[IsOperation] = 1",
            fields: [
                { name: "ID"        , type: "number" },
                { name: "Role_ID"   , type: "string" },
                { name: "OrgUnit_ID", type: "number" },
                { name: "OrgUnit"   , type: "string" },
            ],
        });
    }

    getContext() {

    }
}

export default new OperationContext();
